import React, { useMemo } from 'react'
import Link from 'next/link'
import LinesEllipsis from 'react-lines-ellipsis'
import { sendEvent } from 'lib/ga'
import StoryCardIndicator from 'components/StoryCardIndicator'
import { THEME, wordsOrDate, storyLink } from 'utils'
import Thumbnail from 'components/Thumbnail'
import { track } from 'lib/whoami'
import { storyTypeCDPMap } from '../../utils/stories'

function PureSimpleStoryCard({ story, options }) {
  const {
    showAuthor = false,
    showAuthorImage = false,
    compact = false,
    large = false,
    flex = false,
    trendingRank = null,
    imageAspectRatio = '16:9',
    featuredImage = false,
    showIndicator = true,
    showImageMobile = false,
    thumbnailProps = {},
    isScheduled = false
  } = options
  const timestamp = wordsOrDate(story.date)

  const imageSrc = useMemo(() => {
    let _imageSrc = story.thumbnail.desktop
    if (featuredImage) {
      if (story.thumbnail.raw_featured || story.thumbnail.raw) {
        const imageUrl = new URL(story.thumbnail.raw_featured || story.thumbnail.raw)
        imageUrl.searchParams.set('width', 515)
        if (!imageUrl.searchParams.get('crop')) {
          imageUrl.searchParams.set('crop', imageAspectRatio)
        }
        _imageSrc = imageUrl.toString()
      } else {
        // if we need featured image but there is none on the story, crop desktop to fit
        const imageUrl = new URL(story.thumbnail.desktop)
        imageUrl.searchParams.set('width', 515)
        imageUrl.searchParams.set('crop', imageAspectRatio)
        _imageSrc = imageUrl.toString()
      }
    }
    return _imageSrc
  }, [story.thumbnail.desktop, story.thumbnail.raw_featured])

  return (
    <>
      <div
        className={`simpleStoryCard ${compact ? 'simpleStoryCard--compact' : ''} ${
          large ? 'simpleStoryCard--large' : ''
        } ${flex ? 'displayFlex' : ''}`}
      >
        {!compact && (
          <div
            className={`simpleStoryCard__imageContainer ${
              showImageMobile ? 'simpleStoryCard__imageContainer--visible-mobile' : ''
            } ${flex ? 'simpleStoryCard__imageContainer--flex' : ''}`}
          >
            <div className='simpleStoryCard__image'>
              <Thumbnail
                src={imageSrc}
                alt={story.title}
                aspectRatio={imageAspectRatio}
                priority={featuredImage}
                style={{ verticalAlign: 'middle', height: 'auto', width: '100%', opacity: isScheduled ? '.6' : '' }}
                {...thumbnailProps}
              />
            </div>
            {!isScheduled && showIndicator && <StoryCardIndicator story={story} />}
          </div>
        )}
        <div
          className={`simpleStoryCard__details ${trendingRank ? 'simpleStoryCard__details--inline' : ''} ${
            flex ? 'simpleStoryCard__details--flex' : ''
          }`}
        >
          {trendingRank && <div className='simpleStoryCard__rank'>{trendingRank}</div>}
          <h2 className='simpleStoryCard__title'>
            {story?.title && (
              <LinesEllipsis
                style={{ whiteSpace: 'pre-wrap' }}
                text={story.title}
                maxLine={compact ? '3' : '4'}
                ellipsis='...'
                trimRight
                basedOn='letters'
              />
            )}
          </h2>
          {showAuthor && (
            <div className='simpleStoryCard__meta'>
              {showAuthorImage && story.author.avatar && (
                <img src={story.author.avatar} className='authorImage' alt='' />
              )}
              <div className='byline'>
                <span className='byline__authorname'>{story.author.name}</span>
                <span className='byline__timestamp'>{timestamp}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <style jsx>{`
        .simpleStoryCard {
          display: block;
          margin-bottom: 6px;
        }
        .storyCardIndicator {
          margin-left: -8px;
          margin-bottom: -1px;
        }
        .simpleStoryCard__imageContainer {
          position: relative;
          overflow: hidden;
        }
        .displayFlex {
          display: flex;
        }
        .simpleStoryCard__imageContainer--flex {
          flex-basis: 66.6667%;
          max-width: 66.6667%;
          padding: 0.25rem;
        }
        .simpleStoryCard__details--flex {
          flex-basis: 33.3333%;
          max-width: 33.3333%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 0.25rem 0 0.75rem 1.5rem;
        }
        .simpleStoryCard__image {
          width: 100%;
          display: block;
          margin-bottom: 0.5rem;
          border: 1px solid #f5f5f5;
        }
        .simpleStoryCard__title {
          color: ${THEME.COLORS.TEXTCOLOR};
          font-size: 0.875rem;
          font-weight: 600;
          text-transform: none;
          margin-top: 0;
          margin-bottom: 0;
        }
        .simpleStoryCard__meta {
          margin: 0;
          display: flex;
          flex-flow: row;
          justify-content: flex-start;
          align-items: center;
          align-content: center;
          align-self: flex-start;
          text-align: left;
        }

        .simpleStoryCard--playlist & {
          font-size: 0.6875rem;
          font-weight: 500;
          margin-bottom: 0.25rem;
        }
        .simpleStoryCard--playlist span {
          color: ${THEME.COLORS.GOLD};
        }
        .authorImage {
          display: none;
          border-radius: 50%;
        }
        .byline {
          letter-spacing: normal;
          color: ${THEME.COLORS.TEXTCOLOR};
          font-size: 0.75rem;
          font-weight: 500;
          line-height: 1.2;
          text-transform: capitalize;
        }
        .byline__authorname {
          color: #222 !important;
        }
        .byline__timestamp {
          display: block;
          font-size: 90%;
          padding-top: 0.05rem;
          font-weight: 400;
          font-style: italic;
          text-transform: none;
          color: #999 !important;
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM - 1}px) {
          .authorImage {
            display: inline-flex;
            width: 2rem;
            height: 2rem;
            margin: 0 0.5rem 0 0;
            position: relative;
            background: $white;
            overflow: hidden;
          }
        }
        .simpleStoryCard__rank {
          color: ${THEME.COLORS.LIGHTGRAY};
          font-size: 3rem;
          font-weight: 800;
          text-transform: none;
          padding-right: 14px;
        }
        .simpleStoryCard__details--inline {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
        }
        .simpleStoryCard--large .simpleStoryCard__title {
          font-size: 1.125rem;
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM - 1}px) {
          .simpleStoryCard--large .simpleStoryCard__title {
            font-size: 1.5rem;
          }
        }
        .simpleStoryCard--compact {
          display: flex;
          justify-content: space-between;
        }

        .simpleStoryCard--compact .simpleStoryCard__title {
          font-size: 0.875rem;
          margin-bottom: 0;
        }
        .simpleStoryCard--compact .simpleStoryCard__imageContainer {
          display: flex;
          overflow: visible;
          align-items: center;
        }
        .simpleStoryCard--compact .simpleStoryCard__image {
          margin-bottom: 0;
          margin-right: 4px;
          width: 50px;
          max-width: 50px;
        }
      `}</style>
    </>
  )
}

export const SimpleStoryCard = ({ story, options = {} }) => {
  const { link = null, isScheduled = false } = options

  const handleSimpleStoryCardCDPEvent = () => {
    if (!story) return
    sendEvent('Story Card', 'Click', { label: story?.type, value: story?.id })
    track('ContentClicked', {
      content: {
        src: story?.url,
        type: storyTypeCDPMap[story?.type],
        barstoolBrandID: story?.brand_id ? String(story?.brand_id) : undefined,
        barstoolTalentID: String(story?.author?.id),
        barstoolPodcastID: story?.post_type_meta?.podcast?.id ? String(story?.post_type_meta?.podcast?.id) : undefined,
        barstoolPostID: String(story?.id),
        imageSrc: story?.thumbnail?.featured,
        componentName: 'SimpleStoryCard'
      }
    })
  }

  return isScheduled ? (
    <PureSimpleStoryCard story={story} options={options} />
  ) : (
    <Link prefetch={false} {...(link ?? storyLink(story))} legacyBehavior>
      <a onClick={handleSimpleStoryCardCDPEvent}>
        <PureSimpleStoryCard story={story} options={options} />
      </a>
    </Link>
  )
}

export default SimpleStoryCard
